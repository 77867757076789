<template>
  <div>
    <el-menu active-text-color="#ffd04b" :default-active="ind" text-color="#fff" class="menu">
      <template v-for="item in routerInfo" :key="item.name">
        <el-menu-item @click="toTarget(item.path)" :index="item.name">
          <el-icon>
            <component :is="item.meta.icon" />
          </el-icon>
          <template #title>{{ store.state.user.lang === 'zh' ?
              item.meta.title :
              t('dbMenus.' + item.meta.title)
            }}</template>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>


<script setup>
import { ref, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from "vue-i18n"

const route = useRoute()
const router = useRouter()
const store = useStore()
const { t } = useI18n()

const props = defineProps({
  routerInfo: Object
})

const ind = ref('cable')

watch(
  () => route.name,
  () => {
      ind.value = route.name
  }
)

const toTarget = function (name) {
  if (name === route.name) return
  router.push({ name })
}

onMounted(()=>{
  ind.value = route.name
})
</script>

<style scoped>
.menu {
  background: rgba(76, 175, 180, 0.3);
  border-radius: 20px;
  margin-right: 20px;
}



</style>

<style>
.el-menu-item.is-active {
  background: teal !important;
  border-radius: 20px;
  color: thistle !important;
}
</style>
