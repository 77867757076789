
<template>
  <div class="container manage-center-container">
    <el-container>
      <el-aside>
        <manage-aside :routerInfo="asyncRouters[0].children[4].children" />
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import ManageAside from './leftside/index.vue'

export default {
  name: 'ManageCenter',
  components: {
    ManageAside,
  },
  // data() {
  // return {
  // active: '',
  // managerouters: [],
  //      isCollapse: false
  // }
  // },
  computed: {
    ...mapGetters('router', ['asyncRouters']),
  },
  // beforeCreate() {
  //      this.getManageRouter()
  // },
  watch: {
    $route() {
      this.toDefaultRoute()
    }
  },

  methods: {
    // getManageRouter() {
    //   for (let item in asyncRouters[0].children) {
    //     if (asyncRouters[0].children[item].name == this.$route.name) {
    //         this.managerouters =  asyncRouters[0].children[item].children
    //     }
    //   }
    // },
    toDefaultRoute() {
      if (this.$route.name === 'managecenter') {
        this.$router.push({ name: 'cable' })
      }
    }
  },
  mounted() {
    this.toDefaultRoute()
  }
}
</script>
